<template>
    <div class="homeDiv">
        <div class="buttonsreport" v-if="!showGetFiles && !showingCheckList">
            <div>
                <smallbutton toplabel="Home" buttonclass="buttonprimary widebutton" @click="gohome()"/>
            </div>
            <select name="" id="" class='reportdropdown' v-model="selectedReportId" required @change="selectReport()">
                <option v-for="r in reportList" :key="r.id" :value="r.id" >
                    {{r.name}}
                </option>
            </select>
            <!-- <optionbutton :itemList="reportList" @select="selectReport" :useSmallButtons="paramTrue"/> -->
            
            
        </div>
        <div v-if="selectedReport!=null ">
            <div class="horizFlex" v-if="!showGetFiles && !showingCheckList">
                <div class="horizFlex">
                    
                    <select v-if="!showLocationsWithAll" name="" id="" class='filterselect' v-model="locationId" required @change="selectLocation()">
                        <option v-for="loc in locationList" :key="loc.id" :value="loc.id" >
                            {{loc.name}}
                        </option>
                    </select>
                    <select v-if="showLocationsWithAll" name="" id="" class='filterselect' v-model="locationId" required @change="selectLocation()">
                        <option v-for="loc in locationListWithAll" :key="loc.id" :value="loc.id" >
                            {{loc.name}}
                        </option>
                    </select>
                    <div class="vertFlex">
                        <select name="" id="" class='filterselect' v-model="studentId" required v-if="showStudentFilter && showStudentsWithAll" >
                            <option v-for="s in studentList_wAll" :key="s.id" :value="s.id" >
                                {{s.name}}
                            </option>
                        </select>
                        <select name="" id="" class='filterselect' v-model="studentId" required v-if="showStudentFilter && !showStudentsWithAll" >
                            <option v-for="s in studentList" :key="s.id" :value="s.id" >
                                {{s.name}}
                            </option>
                        </select>
                        
                        <div class="horizFlex"  v-if="showStudentFilter">
                            <input type="checkbox" v-model="includeInactiveStudents" class="checkbox" @change="getStudents()"/>
                            <p class="checklabel">include inactive</p>
                        </div>
                        
                    </div>
                    <div class="horizFlex padded" v-if="selectedReport.divName=='sponsordiv'">
                       
                       <select name="" id="" class='filterselect' v-model="filterBySponsor" required @change="goreport()" v-if="showSponsor">
                           <option v-for="c in sponsorList" :key="c.Name" :value="c.Name" >
                               {{c.Name}}
                           </option>
                       </select>
                      
                   </div>
                    <input type="date" v-model="date1" v-if="showDateFilter" class='filterselect'/>  
                    <p v-if="showDateFilter">to</p>
                    <input type="date" v-model="date2" v-if="showDateFilter" class='filterselect'/>  
                    
                    
                    <div class="horizFlex padded wrap" v-if="selectedReport.divName=='certificatediv'">
                        <select name="" id="" class='filterselect' v-model="certProcedureName" required >
                                    <option v-for="c in procedureList" :key="c.Name" :value="c.Name" >
                                        {{c.Name}}
                                    </option>
                        </select>
                    </div>
                    <div class="horizFlex padded" v-if="selectedReport.divName=='onboarddiv'">
                        <p >Start Date</p>
                        <input type="date" v-model="date1" class='filterselect' />  
                      
                        <select name="" id="" class='filterselect' v-model="filterByStudentGroupId" required @change="goreport()" v-if="showStudentGroup" >
                            <option v-for="c in studentGroupList" :key="c.ID" :value="c.ID" >
                                {{c.Name}}
                            </option>
                        </select>
                 
                    </div>
                    
                    <div class="horizFlex padded" v-if="selectedReport.divName=='tprdiv'">
                        <p >Start Date</p>
                        <input type="date" v-model="startdate" class='filterselect' />  
                    </div>

                    <div class="horizFlex padded" v-if="selectedReport.divName=='testdiv'">
                        
                        <select name="" id="" class='filterselect' v-model="filterByTestTypeId" required @change="goreport()"  >
                            <option v-for="c in testTypeList" :key="c.ID" :value="c.ID" >
                                {{c.Name}}
                            </option>
                        </select>
                 
                    </div>

                    <smallbutton toplabel="Go" @click="goreport" buttonclass="buttonprimary narrowbutton"/>
                    
                    <smallbutton toplabel="Download Files" @click="proceduresbystudentdate" v-if="selectedReport.divName=='onboarddiv' && haveData_onboard" buttonclass="buttonprimary filesbutton"/>
                
                    <!-- <smallbutton :toplabel="detailButtonName" @click="toggleDetail" buttonclass="buttonprimary detailbutton" v-if="showDetailButton"/> -->
                    <p v-if="pleaseWait" class="pleasewait">Please Wait...</p>
                    <p v-if="responseMessage!=null" class="responsemessage">{{responseMessage}}</p>
                </div>

                <div>
                    <smallbutton toplabel="Print" @click="printReport()" buttonclass="buttonprimary narrowbutton" v-if="showPrintButton"/>
                    <smallbutton :toplabel="commentLabel" @click="showComments=!showComments" buttonclass="buttonprimary detailbutton" v-if="haveData && selectedReport.divName=='timeandgradediv'"/>
                </div>
            </div>
           
            <div class="horizFlex padded wrap" v-if="selectedReport.divName=='newstudentdiv'">
                        <p>Filter By</p>
                        <select name="" id="" class='filterselect' v-model="filterByProductId" required @change="goreport()">
                            <option v-for="c in productList" :key="c.id" :value="c.id" >
                                {{c.name}}
                            </option>
                        </select>
                        <select name="" id="" class='filterselect' v-model="filterByStudentGroupId" required @change="goreport()" v-if="showStudentGroup">
                            <option v-for="c in studentGroupList" :key="c.ID" :value="c.ID" >
                                {{c.Name}}
                            </option>
                        </select>
                        <select name="" id="" class='filterselect' v-model="filterBySponsor" required @change="goreport()" v-if="showSponsor">
                            <option v-for="c in sponsorList" :key="c.Name" :value="c.Name" >
                                {{c.Name}}
                            </option>
                        </select>
                        <p >Start Date</p>
                        <input type="date" v-model="startdate" class='filterselect' />  
                        <div v-for="s in statusList" :key="s.ID" :value="s.ID"  class="horizFlex">
                            <input type="checkbox" v-model="s.IsDefault" class="checkbox" />
                            <p class="checklabel2">{{ s.Name }}</p>
                        </div>
                    </div>
            <!-- GRADE -->
            <div id="gradediv" ref="gradediv" v-if="selectedReport.divName=='gradediv' && haveData_grade" class="reportframe" >
                <div class="reportframe" >
                    <img alt="school logo" :src="schoolLogoFilePath"  class="reportlogo" >
                </div>
                <div class="rightjustified">
                        <input class="margin10" type="button" onclick="window.print()" value="Print" v-if="inPrint" />
                    </div>
                <div class="vertFlex padded" >
                    <p class="titlelabel bold smallmargin">Daily Student Grade Record</p>
                    <p class="titlelabel smallmargin">{{ gradedata.Name }}</p>
                    <p class="smallmargin" v-if="showContact">Phone: {{ gradedata.contact.PhoneNumber1_Formatted }}</p>
                    <p class="smallmargin" v-if="showContact">Email: {{ gradedata.contact.EmailAddress }}</p>
                    <p class="smallmargin" v-if="showContact">Address: {{ gradedataaddress  }} </p>
                    <p class="smallmargin" v-if="showContact">Contract #: {{ gradedata.contractNumber  }} </p>
                    <p class="smallmargin">Theory Date:{{ formatDate(gradedata.StartDate1) }}</p>
                    <p class="smallmargin">Graduated:{{ formatDate(gradedata.termDate) }}</p>
                </div>
                <div class="vertFlex padded" >
                    <div class="horizFlex bottombordered">
                        <p class="joblabelfixed">Course</p>
                        <div v-for="s in gradedata.dateList" :key="s" :value="s">
                            <p class="scorelabelfixed bold">{{formatDateWithTime(s)}}</p>
                        </div>
                    </div>
                   
                    <div  v-for="j in gradedata.gradeReportList" :key="j" :value="j">
                        <div class="horizFlex bottombordered">
                            <linkbutton class="joblabellink bold blue" :toplabel=" formatJobName(j.jobName) " @click="j.showDetail = !j.showDetail" />
                            <div v-for="s in j.gradeList" :key="s" :value="s">
                                <p class="scorelabelfixed">{{formatTextForPassFail(s)}}</p>
                            </div>
                        </div>
                        
                        <div v-if="j.showDetail">
                            <div class="horizFlex bottombordered" v-for="k in j.itemList" :key="k" :value="k">
                                <p class="joblabelfixed bold">{{ formatJobName(k.jobName) }}</p>
                                <div v-for="s in k.gradeList" :key="s" :value="s">
                                    <p class="scorelabelfixed">{{formatTextForPassFail(s)}}</p>
                                </div>
                            </div>
                        </div>

                    </div>

                    <!-- old -->
                    <!-- <div class="horizFlex bottombordered" v-for="j in courseList_TotalsOnly" :key="j" :value="j">
                        <p :class="jobClass(j)">{{ formatJobName(j) }}</p>
                        <div v-for="s in gradedata.dateList" :key="s" :value="s">
                            <p class="scorelabelfixed">{{getscore(j,s)}}</p>
                        </div>
                    </div> -->
                </div>
            </div>

            <!-- TIME -->
            <div id="timediv" ref='timediv' v-if="selectedReport.divName=='timediv' && haveData_time" class="reportframe">
                <div class="reportframe" >
                    <img alt="school logo" :src="schoolLogoFilePath"  class="reportlogo" >
                </div>
                <div class="rightjustified">
                        <input class="margin10" type="button" onclick="window.print()" value="Print" v-if="inPrint" />
                    </div>
                <div class="vertFlex padded" v-if="haveData_time">
                    <p class="titlelabel bold smallmargin">Daily Student Attendance Record</p>
                    <p class="titlelabel smallmargin">{{ timedata.Name }}</p>
                    <p class="smallmargin" v-if="showContact">Phone: {{ timedata.contact.PhoneNumber1_Formatted }}</p>
                    <p class="smallmargin" v-if="showContact">Email: {{ timedata.contact.EmailAddress }}</p>
                    <p class="smallmargin" v-if="showContact">Address: {{ timedataaddress  }} </p>
                    <p class="smallmargin" v-if="showContact">Contract #: {{ timedata.contractNumber  }} </p>
                    <p class="smallmargin">Theory Date:{{ formatDate(timedata.StartDate1) }}</p>
                    <p class="smallmargin">Graduated:{{ formatDate(timedata.termDate) }}</p>
                </div>
                <div class="vertFlex padded" v-if="haveData_time">
                    <div class="horizFlex bottombordered">
                        <p class="timeleftlabelfixed">Date</p>
                        <div v-for="j in timedata.jobList" :key="j" :value="j">
                            <p class="timelabelfixed bold">{{j}}</p>
                        </div>
                    </div>
                   

                    <div class="horizFlex bottombordered" v-for="d in timedata.dateList" :key="d" :value="d">
                        <p class="timeleftlabelfixed bold">{{ formatDatefull(d) }}</p>
                        <div v-for="j in timedata.jobList" :key="j" :value="j">
                            <p class="timelabelfixed">{{gettime(j,d)}}</p>
                        </div>
                    </div>
                    <div class="horizFlex bottombordered" >
                        <p class="timeleftlabelfixed bold">Total</p>
                        <div v-for="j in timedata.jobList" :key="j" :value="j">
                            <p class="timelabelfixed bold">{{gettime_totalforjob(j)}}</p>
                        </div>
                    </div>
                    
                </div>
            </div>
            <!-- TIME AND GRADE -->
            <div id="timeandgradediv" ref="timeandgradediv" v-if="selectedReport.divName=='timeandgradediv' && haveData_tg" class="reportframe">
                

                
                                        
                
                
                <div class="timegradedatadiv" v-if="haveData_tg">
                    
                   <div></div>
                    
                    <div class="centered">
                        <div class="reportframe" >
                            <img alt="school logo" :src="schoolLogoFilePath"  class="reportlogo" >
                        </div>
                        <div class=" vertFlex padded" v-if="haveData_tg">
                            <p class="titlelabel bold smallmargin">Student Performance Record</p>
                            <p class="titlelabel smallmargin">{{ timegradedata.Name }}</p>
                            <p class="smallmargin" v-if="showContact">Phone: {{ timegradedata.contact.PhoneNumber1_Formatted }}</p>
                            <p class="smallmargin" v-if="showContact">Email: {{ timegradedata.contact.EmailAddress }}</p>
                            <p class="smallmargin" v-if="showContact">Address: {{ timegradedataaddress  }} </p>
                            <p class="smallmargin" v-if="showContact">Contract #: {{ timegradedata.contractNumber  }} </p>
                            <p class="smallmargin">Theory Date:{{ formatDate(timegradedata.StartDate1) }}</p>
                            <p class="smallmargin">Graduated:{{ formatDate(timegradedata.termDate) }}</p>
                            <p class="smallmargin">Instructor(s):{{ instructorString }}</p>
                        </div>  
                        <p class="titlelabel bold smallmargin">Course Hours</p>
                  
                        <div class="">
                                <div class="horizFlex" v-for="s in timegradedata.timeLists" :key="s.name" :value="s.name">
                                    <p class="joblabelfixed ">{{s.name}}</p>
                                    <p class="scorelabelfixed">{{gettimetotal(s)}}</p>
                                </div>
                                <div class="horizFlex">
                                    <p class="joblabelfixed bold">Course Total</p>
                                    <p class="scorelabelfixed bold">{{gettimetotal_all()}}</p>
                                    
                                </div>
                                <div class="horizFlex">
                                    <p class="joblabelfixed bold">BTW Total</p>
                                    <p class="scorelabelfixed bold">{{gettimetotal_all(true)}}</p>
                                    
                                </div>
                        </div>
                  
                        <p class="titlelabel bold smallmargin">Course Grades</p>
                   
                        <div class="">
                            <div class="horizFlex" v-for="s in timegradedata.dataItemLists" :key="s.name" :value="s.name">
                                <p class="joblabelfixed ">{{s.name}}</p>
                                <p class="scorelabelfixed">{{getgradetotal_applytotaltype(s)}}</p>
                            </div>
                            
                        </div>
                        <div class="vertFlex padded" v-if="showComments">
                            <label class="commentheader bold">Instructor Comments</label>
                            <div v-for="c in comments" :key="c" :value="c" class="vertFlex">
                                <div v-if="c!=null" class="vertFlex">
                                    <label class="commentclasstitle bold">{{ commentTitle(c) }}</label>
                                    <label class="commentclass" >{{ c }}</label>
                                </div>

                            </div>
                        </div>
                    </div>
                    
                    <div class="rightjustified">
                        <input class="margin10" type="button" onclick="window.print()" value="Print" v-if="inPrint" />
                    </div>
                </div>
                
            </div>
            <!-- NEW STUDENT -->
            <div id="newstudentdiv" ref="newstudentdiv" v-if="selectedReport.divName=='newstudentdiv'" class="reportframe">
                <div class="reportframe" >
                    <img alt="school logo" :src="schoolLogoFilePath"  class="reportlogo" >
                </div>
                <div class="rightjustified">
                        <input class="margin10" type="button" onclick="window.print()" value="Print" v-if="inPrint" />
                    </div>
                

                <div class="vertFlex padded" v-if="haveData_new">
                    
                    <p class="titlelabel bold smallmargin">New Student List ({{ newstudentdata.length }})</p>
                    <p class="smallmargin">{{ selectedLocationName }}</p>
                    <p class="smallmargin">{{ selectedProductName }}</p>
                    <p class="smallmargin">{{ formatDateForHeader(date1) }} to {{ formatDateForHeader(date2) }}</p>
                </div>
                <div class="vertFlex padded" v-if="haveData_new">
                    <div class="horizFlex bottombordered">
                        <p class="studentlabelfixed bold">Student</p>
                        
                        <p class="datelabelfixedmed bold" v-if="showLocationsWithAll">Location</p>
                        <p class="datelabelfixedmed bold" v-if="showDate1">{{startDate1Text}}</p>
                        <p class="datelabelfixedmed bold" v-if="showDate2">{{startDate2Text}}</p>
                        <p class="datelabelfixedmed bold">Graduated</p>
                        <p class="datelabelfixedmed bold">Course</p>
                        <p class="datelabelfixedmed bold" v-if="showSponsor">Sponsor</p>
                    </div>
                   
                    <div class="scrollvert">
                        <div class="horizFlex bottombordered" v-for="s in newstudentdata" :key="s" :value="s">
                     
                            <p class="studentlabelfixed">{{s.Name}}</p>
                            <p class="datelabelfixedmed" v-if="showLocationsWithAll">{{s.locationName}}</p>
                            <p class="datelabelfixedmed" v-if="showDate1">{{formatDatefull(s.StartDate1)}}</p>
                            <p class="datelabelfixedmed" v-if="showDate2">{{formatDatefull(s.StartDate2)}}</p>
                            <p class="datelabelfixedmed">{{formatDatefull(s.TermDate)}}</p>
                            <p class="datelabelfixedmed">{{s.productName}}</p>
                            <p class="datelabelfixedmed" v-if="showSponsor">{{s.sponsor}}</p>
                    </div>
                    </div>
                    
                </div>
            </div>

            <!-- TPR -->
            <div id="tprdiv" ref="tprdiv" v-if="selectedReport.divName=='tprdiv'" class="reportframe">
                <div class="reportframe" >
                    <img alt="school logo" :src="schoolLogoFilePath"  class="reportlogo" >
                </div>
                <div class="rightjustified">
                        <input class="margin10" type="button" onclick="window.print()" value="Print" v-if="inPrint" />
                    </div>
                

                <div class="vertFlex padded" v-if="haveData_tpr">
                    
                    <p class="titlelabel bold smallmargin">TPR List</p>
                    <p>{{ formatDateForHeader(date1) }} to {{ formatDateForHeader(date2) }}</p>
                </div>
                <div class="vertFlex padded" v-if="haveData_tpr">
                    <div class="horizFlex bottombordered">
                        <p class="studentlabelfixed_tpr bold">Student</p>
                        <p class="studentlabelfixed_tpr bold">Location</p>
                        <p class="studentlabelfixed_tpr bold">Course</p>
                        <p class="datelabelfixednarrow bold">Entered Date</p>
                        <p class="datelabelfixednarrow bold">Proficient Date</p>
                        <p class="datelabelfixednarrow bold">Proficient By</p>
                        <p class="datelabelfixednarrow bold">Posted Date</p>
                        <p class="datelabelfixednarrow bold">Posted Hours</p>
                        <p class="datelabelfixednarrow bold">Posted Score</p>
                        <p class="datelabelfixednarrow bold">Post Result</p>
                        <p class="datelabelfixednarrow bold">Posted in 2 Days</p>
                        
                        
                      
                    </div>
                   
                    <div class="scrollvert">
                        <div class="horizFlex bottombordered" v-for="s in tprdata" :key="s" :value="s">
                     
                            <p class="studentlabelfixed_tpr">{{s.studentName}}</p>
                            <p class="studentlabelfixed_tpr">{{s.locationName}}</p>
                            <p class="studentlabelfixed_tpr">{{s.courseName}}</p>
                            <p class="datelabelfixednarrow">{{formatDatefull(s.enteredDate)}}</p>
                            <p class="datelabelfixednarrow">{{formatDatefull(s.proficientDate)}}</p>
                            <p class="datelabelfixednarrow">{{s.proficientInstructorName}}</p>
                            <p class="datelabelfixednarrow">{{formatDatefull(s.postedDate)}}</p>
                            <p class="datelabelfixednarrow">{{s.postedHours}}</p>
                            <p class="datelabelfixednarrow">{{s.postedScore}}</p>
                            <p class="datelabelfixednarrow">{{s.postedSuccess}}</p>
                            <p class="datelabelfixednarrow">{{s.postedWithinTwoDays}}</p>
                  
                    </div>
                    </div>
                    
                </div>
            </div>


            <!-- WHO TRAINED WHO -->
            <div id="whodiv" ref="whodiv" v-if="selectedReport.divName=='whodiv'" class="reportframe">
                <div class="reportframe" >
                    <img alt="school logo" :src="schoolLogoFilePath"  class="reportlogo" >
                </div>
                <div class="rightjustified">
                        <input class="margin10" type="button" onclick="window.print()" value="Print" v-if="inPrint" />
                    </div>
                

                <div class="vertFlex padded" v-if="haveData_who">
                    
                    <p class="titlelabel bold smallmargin">Training List</p>
                    <p>{{ formatDateForHeader(date1) }} to {{ formatDateForHeader(date2) }}</p>
                </div>
                <div class="vertFlex padded" v-if="haveData_who">
                    <div class="horizFlex bottombordered">
                        <p class="datelabelfixed bold">Instructor</p>
                        <p class="datelabelfixed bold">Student</p>
                        <p class="datelabelfixed bold">Course</p>
                        <p class="datelabelfixed bold">Route</p>
                    </div>
                   
                    <div class="scrollvert">
                        <div class="horizFlex bottombordered" v-for="s in whotrainedwhodata" :key="s" :value="s">
                     
                            <p class="datelabelfixed">{{s.InstructorName}}</p>
                            <p class="datelabelfixed">{{s.StudentName}}</p>
                            <p class="datelabelfixed" >{{s.Name}}</p>
                            <p class="datelabelfixed" >{{s.routeNumber}}</p>
                    </div>
                    </div>
                    
                </div>
            </div>

            <div v-if="selectedReport.divName=='onboarddiv' && showGetFiles" class="reportframe">
                <p class="titlelabel bold smallmargin">Download Files</p>
                <gradesheetsd  ref="gradesheetsd" :studentList="onboardstudents" :dateList="onboardprocdates" @close="showGetFiles=false"/>
            </div>


            <!-- ONBOARD TRACKER -->
            <div id="onboarddiv" ref="onboarddiv" v-if="selectedReport.divName=='onboarddiv' && !showGetFiles" class="reportframe">
                <div class="reportframe" >
                    <img alt="school logo" :src="schoolLogoFilePath"  class="reportlogo" >
                </div>
                <!-- <div class="leftjustified" v-if="haveData_onboard">
                    <button @click="proceduresbystudentdate">Download Files</button>
                </div> -->
                <div class="rightjustified">
                        
                        <input class="margin10" type="button" onclick="window.print()" value="Print" v-if="inPrint" />
                    </div>
                

                <div class="vertFlex padded" v-if="haveData_onboard">
                    
                    <p class="titlelabel bold smallmargin">Onboard Tracking</p>
                    <p>Start Date: {{ formatDateForHeader(date1) }} </p>
                </div>
                <div class="vertFlex padded" v-if="haveData_onboard">
                    <div class="horizFlex bottombordered ">
                        <p class="studentlabelfixed bold"></p>
                        <div v-for="o in onboardprocdates" :key="o" :value="o" :class="superheaderclass(o)">
                            <p class="otlabelheaderfixed bold " >{{ superHeaderString(o) }}</p>
                        </div>
                        
                    </div>


                    <div class="horizFlex bottombordered">
                        <p class="studentlabelfixed ">Student</p>
                        <div v-for="o in onboardprocdates" :key="o" :value="o" :class="subheaderclass(o)">
                            <p class="otlabelheaderfixed bold " >{{o.courseName}}  {{  o.overrideHeaderString}}</p>
                        </div>
                        <!-- <p :class="subheaderclass(o)" v-for="o in onboardprocdates" :key="o" :value="o">{{o.courseName}} {{  o.overrideHeaderString}}</p> -->
                     
                    </div>
                   
                    <div class="scrollvert">
                        <div class="horizFlex bottombordered" v-for="s in onboardstudents" :key="s" :value="s">
                     
                            <p class="studentlabelfixed">{{s.name}}</p>
                            <!-- :class="subheaderclass(o)" -->
                            <div v-for="o in onboardprocdates" :key="o" :value="o" :class="subheaderclass(o)">
                                <p :class="otLabelClass(s,o)">{{getOnboardValue(s,o)}}</p>
                            </div>
                  
                    </div>
                    </div>
                    
                </div>
            </div>

            <!-- OTEST -->
            <div id="testdiv" ref="testdiv" v-if="selectedReport.divName=='testdiv'" class="reportframe">
                <div class="reportframe" >
                    <img alt="school logo" :src="schoolLogoFilePath"  class="reportlogo" >
                </div>
                <div class="rightjustified">
                        <input class="margin10" type="button" onclick="window.print()" value="Print" v-if="inPrint" />
                    </div>
                

                <div class="vertFlex padded" v-if="haveData_test">
                    
                    <p class="titlelabel bold smallmargin">CDL Testing</p>
                    <p>{{ formatDateForHeader(date1) }} to {{ formatDateForHeader(date2) }}</p>
                </div>
                <div class="vertFlex padded" v-if="haveData_test">
                    <div class="horizFlex bottombordered">
                        <p class="studentlabelfixed bold"></p>
                        <div v-for="o in testdates" :key="o" :value="o" :class="superheaderclass_test(o)">
                            <p class="otlabelheaderfixed bold" >{{ superHeaderString(o) }}</p>
                        </div>
                        
                    </div>
                    <div class="horizFlex bottombordered">
                        <p class="studentlabelfixed bold">Student</p>
                        <p :class="subheaderclass_test(o)" v-for="o in testdates" :key="o" :value="o">{{o.courseName}} {{  o.enteredDateString}}</p>
                        <!-- formatDatefull(o.enteredDate) -->
                    </div>
                   
                    <div class="scrollvert">
                        <div class="horizFlex bottombordered" v-for="s in teststudents" :key="s" :value="s">
                     
                            <p class="studentlabelfixed">{{s.name}}</p>
                            <div v-for="o in testdates" :key="o" :value="o" :class="subheaderclass_test(o)">
                                <p :class="testLabelClass(s,o)">{{getTestValue(s,o)}}</p>
                            </div>
                  
                    </div>
                    </div>
                    
                </div>
            </div>

            <!-- SPONSOR ATTENDANCE -->
            <div id="sponsordiv" ref="sponsordiv" v-if="selectedReport.divName=='sponsordiv'" class="reportframe">
                <div class="reportframe" >
                    <img alt="school logo" :src="schoolLogoFilePath"  class="reportlogo" >
                </div>
                <div class="rightjustified">
                        <input class="margin10" type="button" onclick="window.print()" value="Print" v-if="inPrint" />
                    </div>
                

                <div class="vertFlex padded" v-if="haveData_sponsor">
                    
                    <p class="titlelabel bold smallmargin">Sponsor Attendance (Hours)</p>
                    <p>{{ filterBySponsor }}</p>
                    <p>{{ formatDateForHeader(date1) }} to {{ formatDateForHeader(date2) }}</p>
                </div>
                <div class="vertFlex padded" v-if="haveData_sponsor">
                    <div class="horizFlex bottombordered">
                        <p class="studentlabelfixed bold">Student</p>
                        <p class="datelabelfixed bold">Course</p>
                        <p class="datelabelfixed bold" v-if="showSponsor">Sponsor</p>
                        <p class="datelabelfixednarrow bold" v-for="d in sponsordatelist" :key='d' :value="d">{{formatDatefull(d)}}</p>
                    </div>
                   
                    <div class="scrollvert">
                        <div class="horizFlex bottombordered" v-for="s in sponsordata" :key="s" :value="s">
                     
                            <p class="studentlabelfixed">{{s.Name}}</p>
                            <p class="datelabelfixed">{{s.productName}}</p>
                            <p class="datelabelfixed" v-if="showSponsor">{{s.sponsor}}</p>
                            <p class="datelabelfixednarrow" v-for="d in sponsordatelist" :key='d' :value="d">{{sponsor_gethours(s,d)}}</p>
                        </div>
                    </div>
                    
                </div>
            </div>

            <!-- CHECKLIST -->
            <div id="checklistdiv" ref="checklistdiv" v-if="selectedReport.divName=='checklistdiv'" class="reportframe">
                <div class="rightjustified" v-if="haveData_checklist">
                    <smallbutton toplabel="Print" @click="startprint" buttonclass="buttonprimary innerbutton" />
                    <smallbutton toplabel="Close" @click="closeCheckList" buttonclass="buttonprimary innerbutton"/>
                    
                </div>
                <label class="responsemessage">{{ checklistresponseMessage }}</label>
                <div class="reportframe" >
                    <img alt="school logo" :src="schoolLogoFilePath"  class="reportlogo" >
                </div>
                
                <div class=" vertFlex padded" v-if="haveData_checklist">
                        <p class="titlelabel bold smallmargin">Student Check List</p>
                        <p class="titlelabel smallmargin">{{ checklistcontact.name }}</p>
                        <p class="smallmargin" v-if="showContact">Phone: {{ checklistcontact.Contact.PhoneNumber1_Formatted }}</p>
                        <p class="smallmargin" v-if="showContact">Email: {{ checklistcontact.Contact.EmailAddress }}</p>
                        <p class="smallmargin" v-if="showContact">Address: {{ checklistcontactaddress  }} </p>
                        <p class="smallmargin" v-if="showContact">Contract #: {{ checklistcontact.contractNumber  }} </p>
                        <p class="smallmargin">Theory Date:{{ formatDate(checklistcontact.StartDate1) }}</p>
                        <p class="smallmargin">Graduated:{{ formatDate(checklistcontact.termDate) }}</p>
                
                </div> 
                <div class="vertFlex padded" v-if="haveData_checklist">
                    <div class="horizFlex bottombordered">
                        <p class="datelabelfixed bold">Completed</p>
                        <p class="studentlabelfixed bold">Item</p>
                        <p class="datelabelfixed bold">Date/Time</p>
                        <p class="datelabelfixed bold">Checked By</p>
                    </div>
                   
                    <div class="checklistscroll">
                        <div class="horizFlex bottombordered" v-for="s in checklistdata" :key="s" :value="s">
                            <div class="datelabelfixed">
                                <input type="checkbox" v-model="s.checked" class="checklistcheck" @change="updatecheck(s,true)">
                            </div>
                            
                            <p class="studentlabelfixed">{{s.name}}</p>
                            <div class="datelabelfixed">
                                <input type="date" v-model="s.checkedDateTime" class="checklistinput darkfontdisabled"  :disabled="s.locked">
                            </div>
                            <div class="datelabelfixed">
                                <select name="" id="" class='checklistinput darkfontdisabled' v-model="s.personId_CheckedBy"  :disabled="s.locked">
                                    <option v-for="inst in instructorList" :key="inst.id" :value="inst.id" >
                                        {{inst.name}}
                                    </option>
                                </select>
                            </div>
                            <linkbutton class='checklistinput' toplabel="Update" v-if="s.locked && s.checked" @click="s.locked=false "/>
                            <linkbutton class='checklistinput' toplabel="Save" v-if="!s.locked" @click="updatecheck(s)"/>
                            <linkbutton class='checklistinput' toplabel="Cancel" v-if="!s.locked" @click="s.locked=true"/>

                            <!-- <p class="datelabelfixed">{{formatDatefull(s.checkedDateTime)}}</p> -->
                            <!-- <p class="datelabelfixed">{{s.personId_CheckedByName}}</p> -->
                        </div>
                    </div>
                    
                </div>
            </div>

            <!-- CERTIFICATE -->
            <div id="certificatediv" ref="certificatediv" v-if="selectedReport.divName=='certificatediv'" class="reportframe">
                <!-- <div class="rightjustified noprint">
                            <input class="margin10 " type="button" onclick="window.print()" value="Print"  v-if="inPrint"/>
                        </div> -->
                <div class="centered certmargin">
                    <div class="reportframe" >
                        <img alt="school logo" :src="schoolLogoFilePath"  class="reportlogocert" >
                    </div>
                    
                    

                    <div class="vertFlex padded" v-if="haveData_certificate">
                        
                        <label class="certname">{{ certificateData.name }}</label>
                        <label class="certsub">Successfully Completed the {{certProcedureName}} Course</label>
                        <label class="certsub">At {{certificateData.chainName}} - {{certificateData.LocationName_Default}}</label>
                        <label class="certsub">On {{ formatDateForCert(certificateData.termDate) }}</label>
                    </div>
                    <div class="horizFlexStretch">
                        <div>

                        </div>
                        <div class=" signdiv" v-if="haveData_certificate">
                            <br>
                            <br>
                            <div class="rightjustified signlong">
                                <hr class="signlong"/>
                            </div>
                            
                            <label class="certsign">{{certificateData.signerName}}</label>
                            <div class="rightjustified signshort">
                                <hr class="signshort"/>
                            </div>
                            <label class="certsign">Date</label>
                        </div>
                    </div>
               
                </div>
            </div>

            <!-- ALL FILES -->
            <div v-if="selectedReport.divName=='allfilesdiv'" class="reportframe">
                <p class="titlelabel bold smallmargin">Download All Files</p>
                <allfiles  ref="allfiles"  @close="showGetFiles=false"/>
            </div>


        </div>
        <modalmessage ref="modalmessage" title="OFFLINE" body="This action is unavailable Offline."/>
        <!-- <gradesheetstudentday ref="gradesheetstudentday" :studentList="onboardstudents" :dateList="onboardprocdates"/> -->
        <!-- :studentId="selStudentId" :gradeDate="selGradeDate" -->
    </div>
</template>
  
  <script>
  import {mapFields} from 'vuex-map-fields'
  import datamethods from '../../services/datamethods.js'
  import format from '../../format.js'
  import smallbutton from '../controls/button.vue'
  import linkbutton from '../controls/linkbutton.vue'
  import confirm from '../actions/confirm.vue'
  import modalmessage from '../controls/modalmessage.vue'
  import securityhelper from '../../services/securityhelper.js'
  import optionbutton from '../controls/optionbutton.vue'
 import allfiles from '../grade/allfiles.vue'
  import gradesheetsd from '../grade/gradesheetsd.vue'

  export default {
      name:'reports',
      mixins:[datamethods, format,securityhelper],
      components:{
          smallbutton,
          optionbutton,
          confirm,
          modalmessage,
          linkbutton,
          allfiles,
          gradesheetsd

     
   
      },
      data(){
          return{
            lastcheckeditem:null,
            statusList:[],
            instructorList:[],

            selectedReportId:-1,

            responseMessage:null,

            selStudentId:199337,
            selGradeDate:'4/30/2024',

            selectedProductName:null,
            selectedLocationName:null,
            showComments:true,
            includeInactiveStudents:false,
            
            paramTrue:true,
            paramFalse:false,   

            reportList:[
            {
                    id:-1,
                    key:'',
                    name:'--Select Report--',
                    divName:''
                },
                {
                    id:1,
                    key:'grade',
                    name:'Grade Record',
                    divName:'gradediv'
                },
                {
                    id:2,
                    key:'time',
                    name:'Attendance Record',
                    divName:'timediv'
                },
                {
                    id:3,
                    key:'timegrade',
                    name:'Time and Grade Summary',
                    divName:'timeandgradediv'
                },
                {
                    id:4,
                    key:'newstudent',
                    name:'New Students',
                    divName:'newstudentdiv'
                },
                {
                    id:5,
                    key:'who',
                    name:'Training List',
                    divName:'whodiv'
                },
                {
                    id:6,
                    key:'tpr',
                    name:'TPR List',
                    divName:'tprdiv'
                },
                {
                    id:7,
                    key:'onboard',
                    name:'Onboard Tracking',
                    divName:'onboarddiv'
                },
                {
                    id:8,
                    key:'test',
                    name:'CDL Testing',
                    divName:'testdiv'
                },
                {
                    id:9,
                    key:'sponsor',
                    name:'Sponsor Attendance',
                    divName:'sponsordiv'
                },
                
                {
                    id:11,
                    key:'certificate',
                    name:'Certificate',
                    divName:'certificatediv'
                },
                 /* {
                    id:20,
                    key:'allfiles',
                    name:'All Files',
                    divName:'allfilesdiv'
                }  */
            ],
            selectedReport:null,

            studentList:[],
            studentList_wAll:[],
            locationId:-1,
            studentId:-1,
            date1:null,
            date2:null,
            startdate:null,
            testTypeList:[],

            gradedata:{
                ID:0,
                gradeReportList:[]
            },
            showGradeDetail:false,
            timedata:{
                jobList:[]
            },
            timegradedata:{
                timeLists:[]
            },
            whotrainedwhodata:[],
            tprdata:[],

            onboardstudents:[],
            onboardprocdates:[],

            teststudents:[],
            testdates:[],

            sponsordata:[],
            sponsordatelist:[],
            
            instructorString:null,
            
            newstudentdata:[],
            checklistdata:[],
            checklistcontact:{},
            checklistresponseMessage:null,
            comments:[],

            
            sponsorList:[],
            filterByProductId:-1,
            filterBySponsor:'--All Sponsors--',
            filterByStudentGroupId:-1,
            filterByTestTypeId:-1,
            inPrint:false,
            showGetFiles:false,
            locationListWithAll:[],
           
            certificateData:{
                id:0
            },
            procedureList:[],
            certProcedureName:null
          }
      },
      computed:{
        FailedText(){
            var failed = 'Failed'
            if(window.FAILED_TEXT_OVERRIDE!=undefined){
                failed=window.FAILED_TEXT_OVERRIDE
            }
            return failed
        },
        PassedText(){
            var failed = 'Passed'
            if(window.PASSED_TEXT_OVERRIDE!=undefined){
                failed=window.PASSED_TEXT_OVERRIDE
            }
            return failed
        },
        showingCheckList(){
            if(this.selectedReport==null){return false}
            return this.selectedReport.divName=='checklistdiv' && this.haveData_checklist
        },
        showLocationsWithAll(){
            return this.selectedReport.divName=='newstudentdiv'
        },
        showStudentsWithAll(){
            return this.selectedReport.divName=='tprdiv'
        },
        showStartDateFilter(){
            return this.selectedReport.divName=='onboarddiv' || this.selectedReport.divName=='newstudentdiv'
        },
        checklistcontactaddress(){
            if(this.checklistcontact.Contact.AddressLine1==null){return ''}
            return this.checklistcontact.Contact.AddressLine1 + ' ' + this.checklistcontact.Contact.City + ', ' + this.checklistcontact.Contact.StateName + ' ' + this.checklistcontact.Contact.ZipCode
        },
        gradedataaddress(){
            if(this.gradedata.contact.AddressLine1==null){return ''}
            return this.gradedata.contact.AddressLine1 + ' ' + this.gradedata.contact.City + ', ' + this.gradedata.contact.StateName + ' ' + this.gradedata.contact.ZipCode
        },
        timedataaddress(){
            if(this.timedata.contact.AddressLine1==null){return ''}
            return this.timedata.contact.AddressLine1 + ' ' + this.timedata.contact.City + ', ' + this.timedata.contact.StateName + ' ' + this.timedata.contact.ZipCode
        },
        timegradedataaddress(){
            if(this.timegradedata.contact.AddressLine1==null){return ''}
            return this.timegradedata.contact.AddressLine1 + ' ' + this.timegradedata.contact.City + ', ' 
                + this.timegradedata.contact.StateName + ' ' + this.timegradedata.contact.ZipCode
        },
        commentLabel(){
            if(this.showComments){return "Hide Comments"}
            else{return "Show Comments"}
        },
        showStudentGroup(){
            if(window.SHOW_STUDENTGROUP==undefined){return false}
            else{return window.SHOW_STUDENTGROUP}
        },
        showContact(){
            if(window.SHOW_CONTACT_ON_REPORT==undefined){return false}
            else{return window.SHOW_CONTACT_ON_REPORT}
        },
        detailButtonName(){
            if(this.showGradeDetail){
                return "Hide Detail"
            }else{return "Show Detail"}
        },
        showDateFilter(){
            return this.selectedReport.key=='newstudent' || this.selectedReport.key=='who' || this.selectedReport.key=='tpr' || this.selectedReport.key=='test' || this.selectedReport.key=='sponsor'
        },
        showStudentFilter(){
            return this.selectedReport.key!='newstudent' && this.selectedReport.key!='who' && this.selectedReport.key!='onboard' && this.selectedReport.key!='sponsor'
        },
        showDetailButton(){
            return this.selectedReport.key=='grade'
        },
            schoolLogoFilePath(){
            
                return './../' + window.SCHOOL_LOGO
    
            },
            haveData(){
                return this.haveData_grade || this.haveData_time || this.haveData_tg || this.haveData_new || this.haveData_onboard || this.haveData_test 
                    || this.haveData_sponsor || this.haveData_checklist|| this.haveData_certificate
                //return this.gradedata.gradeReportList.length>0 || this.timedata.jobList.length>0 || this.timegradedata.timeLists.length>0 || this.newstudentdata.length>0
            },
            haveData_certificate(){
                return this.certificateData.id>0
            },
            haveData_grade(){
                if(this.gradedata.gradeReportList==null){return false}
                return this.gradedata.ID>0
                return this.gradedata.gradeReportList.length>0
            },
            haveData_time(){
                if(this.timedata.jobList==undefined){ return false}
                return this.timedata.jobList.length>0
            },
            haveData_tg(){
                return this.timegradedata.timeLists.length>0
            },
            haveData_new(){
                return this.newstudentdata.length>0
            },
            haveData_checklist(){
                return this.checklistdata.length>0
            },
            haveData_who(){
                return this.whotrainedwhodata.length >0
            },
            haveData_tpr(){
                return this.tprdata.length>0
            },
            haveData_onboard(){
                return this.onboardstudents.length>0
            },
            haveData_test(){
                return this.teststudents.length>0
            },
            haveData_sponsor(){
                return this.sponsordata.length>0
            },
            courseList_TotalsOnly(){
                if(this.showGradeDetail){return this.gradedata.jobList}
                var totals=[]
                if(this.gradedata==null){return totals}
                if(this.gradedata.jobList.length==0){return totals}
                
              //  console.log('still looking for courses')
                this.gradedata.jobList.forEach(myFunction2)
                function myFunction2(item, index, arr) {
                    let comp=arr[index]
                    if(comp.includes('(Total)') || comp.includes('(Avg)') ||comp.includes('(Out of')){
                        totals.push(comp)
                    }
                }
                return totals
            },
            showPrintButton(){
                switch (this.selectedReport.key) {
                case 'grade':
                    return this.haveData_grade
                    break;
                case 'time':
                    return this.haveData_time           
                    break;
                case 'timegrade':
                    return this.haveData_tg
                    break;
                case 'newstudent':
                    return this.haveData_new
                    break;
                case 'checklist':
                    return this.haveData_checklist
                    break;
                case 'who':
                    return this.haveData_who
                    break;
                case 'tpr':
                    return this.haveData_tpr
                    break;
                case 'test':
                    return this.haveData_test
                    break;
                case 'sponsor':
                    return this.haveData_sponsor
                    break;
                case 'onboard':
                    return this.haveData_onboard
                    break;
                case 'certificate':
                    return this.haveData_certificate
                    break;
                default:
                    break;
            }
            },
          ...mapFields([
              
              'loggedInUser.isAdmin',
              'pleaseWait',
              'pleaseWaitText',
              'locationList',
              'productList',
              'studentGroupList',
              
             

             
          ]),
          startDate1Text(){
            
            if(window.START_DATE1_TEXT==undefined){
                return 'Start Date'
            }else{return window.START_DATE1_TEXT}
        },
        startDate2Text(){
            
            if(window.START_DATE2_TEXT==undefined){
                return 'Start Date 2'
            }else{return window.START_DATE2_TEXT}
        },
        showDate2(){
            
            if(window.SHOW_DATE2==undefined){
                return false
            }else{return window.SHOW_DATE2}
        },
        showDate1(){
            
            if(window.SHOW_DATE1==undefined){
                return true
            }else{return window.SHOW_DATE1}
        },
        showSponsor(){
            
            if(window.SHOW_SPONSOR==undefined){
                return true
            }else{return window.SHOW_SPONSOR}
        }
      },
      props:{
          
          
          
      },
      methods:{
        formatTextForPassFail(text){
            if(text==null){return null}
            var out= text.replace('Passed', this.PassedText)
            out  = out.replace('Failed',this.FailedText)
            return out
        },
        closeCheckList(){
            if(this.checkListItemsInUpdate()){this.checklistresponseMessage="Please save or cancel any check list items that you are updating."; return;}
            this.selectedReport=null;
            this.selectedReportId=-1;
            this.checklistdata=[]
        },
        checkListItemsInUpdate(){
            var inupdate = false
            this.checklistdata.forEach(myFunction2)
                function myFunction2(item, index, arr) {
                    let comp=arr[index]
                    if(!comp.locked){
                        inupdate=true
                    }
                }
            return inupdate
        },
        sponsor_gethours(student, date){
            
            var hours = 0
            if(student.timeAndDateList.datalist!=null){
                student.timeAndDateList.datalist.forEach(myFunction2)
                function myFunction2(item, index, arr) {
                    let comp=arr[index]
                    if(comp.itemDate==date && comp.Name.includes("*")){
                        hours = hours + Number(comp.Grade)
                    }
                }
            }
           if(hours==0){return ''}
            return Number(hours).toFixed(2)
        },
        proceduresbystudentdate(){
            this.showGetFiles=true
            this.$nextTick(()=>{
                this.$refs.gradesheetsd.Show()
            })
            /* this.selStudentId=199337
            this.selGradeDate='4/30/2024'
            this.$refs.gradesheetstudentday.Show() */
        },
        getOnboardValue(s,o){
            var failed=false
            var complete =false
            var found=false
            s.otList.forEach(myFunction2)
                function myFunction2(item, index, arr) {
                    let comp=arr[index]
                    if(comp.courseName==o.courseName && comp.enteredDateString==o.enteredDateString ){
                        failed = comp.isFailure
                        complete = comp.statusName.includes('Complete')
                        found=true
                    }
                }
            if(!found){return ''}
            if(!complete){return 'In Assessment'}
            if(failed){return this.formatTextForPassFail('Failed')}
            else{return this.formatTextForPassFail('Passed')}
        },
        superHeaderString(o){
            if(o.isFirstDate){
                return  this.formatDatefull(o.enteredDate)
            }else{
                return ""
            }
        },
        superheaderclass(o){
            if(o.isFirstDate){
                return  "leftbordersub"
            }else{
                return "nomargindiv"
            }
        },
        superheaderclass_test(o){
            if(o.isFirstDate){
                return  "leftbordersub_test"
            }else{
                return "noborderdiv_test"
            }
        },
        subheaderclass(o){
            if(o.isFirstDate){
                return  "otlabelheaderfixed bold leftbordersub"
            }else{
                return "otlabelheaderfixed bold"
            }

        },
        subheaderclass_test(o){
            if(o.isFirstDate){
                return  "otlabelheaderfixed_test bold leftbordersub_test"
            }else{
                return "otlabelheaderfixed_test bold noborderdiv_test"
            }

        },
        
        onboarddataclass(o){
            if(o.isFirstDate){
                return  "otlabelheaderfixed bold leftborder2"
            }else{
                return "otlabelheaderfixed bold"
            }

        },
        getTestValue(s,o){
            var failed=false
            var found=false
            s.otList.forEach(myFunction2)
                function myFunction2(item, index, arr) {
                    let comp=arr[index]
                    if(comp.courseName==o.courseName && comp.enteredDate + comp.enteredDateString == o.enteredDate + o.enteredDateString){
                        failed = comp.isFailure
                        found=true
                    }
                }
            if(!found){return ''}
            if(failed){return 'Failed'}
            else{return 'Passed'}
        },
        otLabelClass(s,o){
            var cls=""
            var val = this.getOnboardValue(s,o)
            if(val==this.formatTextForPassFail('Failed')){
                cls= "otlabelfixed otfail"
            }else if(val=='In Assessment'){
                cls= "otlabelfixed "
            
            }else{ cls= "otlabelfixed otpass"}

           
            return cls
        },
        testLabelClass(s,o){
            var cls=""
            var val = this.getTestValue(s,o)
            if(val=='Failed'){
                cls= "otlabelfixed_test otfail"
            }else if(val=='In Assessment'){
                cls= "otlabelfixed_test "
            
            }else{ cls= "otlabelfixed_test otpass"}

            
            return cls
        },
        startprint(){
            window.print();
        },
        
        commentTitle(c){
            if(c==undefined){return ''}
            if(c==null){return ''}
            if(c==''){return ''}
            var firstbar = c.indexOf('|')
            if(firstbar>0){return c.substr(0,firstbar-1)}
            else{return ''}
        },
         async printReport() {
            this.inPrint=true

            this.$nextTick(()=>{
                let divname = this.selectedReport.divName

                // Get HTML to print from element
                var prtHtml = document.getElementById(divname).innerHTML ;
                //console.log(prtHtml)
                ////ptrHtml = prtHtml + 'onload="window.print()"'
                //prtHtml=prtHtml +  '&ltscript&gtwindow.print()&lt/script&gt'
              
                // Get all stylesheets HTML
                let stylesHtml = '';
                for (const node of [...document.querySelectorAll('link[rel="stylesheet"], style')]) {
                stylesHtml += node.outerHTML;
                }
               // console.log('styles ' + stylesHtml)
                // Open the print window
                const WinPrint = window.open('', 'PRINT', 'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0');

                WinPrint.document.write(`<!DOCTYPE html>
                <html>
                <head>
                    ${stylesHtml}
                </head>
                <body>
                    ${prtHtml}
                    
                </body>
                
                </html>`);

                this.inPrint=false
            });

            
            
            //WinPrint.document.close();
            
            /* this.$nextTick(()=>{
                WinPrint.window.location.reload()
                WinPrint.focus();
                WinPrint.print();
            }); */
            
           
            //window.setTimeout(() => {WinPrint.focus(); WinPrint.print()}, 200)
           // WinPrint.close();
                


            //await this.$htmlToPaper(divname)

            /* var restorepage = document.body.innerHTML;

           // console.log(divname)
            var printcontent = document.getElementById(divname).innerHTML;
            document.body.innerHTML =printcontent;
            window.print();
            document.body.innerHTML = restorepage;
            this.$forceUpdate(); */
        },
        
        jobClass(job){
            var index = job.indexOf('**')
            if(index ==-1){return 'joblabelfixed bold blue'}
            else{return 'joblabelfixed bold'}
        },
        formatJobName(job){
            var index = job.indexOf('**')
            //console.log('job index ' + job + ' '  + index)
            if(index ==-1){return job}
            var outjob = job.substr(0,index-1)
            return outjob
        },
        toggleDetail(){
            this.pleaseWait=true
            this.showGradeDetail=!this.showGradeDetail
            this.$nextTick(()=>{
                this.pleaseWait=false
            });
        },
        formatDate(dt){
          //  console.log('formatdate ' + dt)
            if(dt==undefined){return ''}
            if(dt.includes('0001')){return ''}
            return this.$options.filters.formatDateTime_mini(dt)   
        },
        formatDateWithTime(dt){
           // console.log('formatdate ' + dt)
            if(dt==undefined){return ''}
            if(dt.includes('0001')){return ''}
            return this.$options.filters.formatDateTime_miniwithtime(dt)   
        },
        formatDateForHeader(dt){
          //  console.log('formatdate ' + dt)
            return this.$options.filters.formatDate(dt + 'T00:00:00') 
        },
        formatDatefull(dt){
            
            if(dt==undefined){return ''}
            if(dt.includes('0001')){return ''}
            return this.$options.filters.formatDateTime_mdy(dt)   
        },
        formatDateForCert(dt){
           // console.log('dt ' + dt)
            if(dt==null){return null}
            if(dt.includes('0001-01-01')){return null}
            return this.$options.filters.formatDate(dt) 
        },
        gohome(){
            this.$emit('gotohome')
        },
        selectReport(){
            let id = this.selectedReportId
            var selrep
            this.reportList.forEach(myFunction2)
                function myFunction2(item, index, arr) {
                    let comp=arr[index]
                    if(comp.id==id){
                        selrep=comp
                    }
                }
            this.selectedReport=selrep


            if(this.selectedReport.key=='who'){
                //def date to yesterday
                let today =new Date();
                this.date1 =new Date().setDate(today.getDate()-1);
                this.date2=new Date().setDate(today.getDate()-1);
                this.date1=this.$options.filters.formatDateForInput( this.date1)
                this.date2=this.$options.filters.formatDateForInput( this.date2)
            }else{
                this.date2=this.$options.filters.formatDateForInputToday()
                let today =new Date();
                this.date1 =new Date().setDate(today.getDate()-30);
                this.date1=this.$options.filters.formatDateForInput( this.date1)
            }
            if(this.studentGroupList.length>1){
                this.filterByStudentGroupId= this.studentGroupList[0].ID
            }
            
        },
        /* selectReport(id){
            var selrep
            this.reportList.forEach(myFunction2)
                function myFunction2(item, index, arr) {
                    let comp=arr[index]
                    if(comp.id==id){
                        selrep=comp
                    }
                }
            this.selectedReport=selrep


            if(this.selectedReport.key=='who'){
                //def date to yesterday
                let today =new Date();
                this.date1 =new Date().setDate(today.getDate()-1);
                this.date2=new Date().setDate(today.getDate()-1);
                this.date1=this.$options.filters.formatDateForInput( this.date1)
                this.date2=this.$options.filters.formatDateForInput( this.date2)
            }else{
                this.date2=this.$options.filters.formatDateForInputToday()
                let today =new Date();
                this.date1 =new Date().setDate(today.getDate()-30);
                this.date1=this.$options.filters.formatDateForInput( this.date1)
            }
            if(this.studentGroupList.length>1){
                this.filterByStudentGroupId= this.studentGroupList[1].ID
            }
            
        }, */
        selectLocation(){
            this.getStudents();
        },
        async getStudents(){
    
            let req = {
                LocationID:this.locationId,
                includeInactive:this.includeInactiveStudents
            }
             this.$store.dispatch('beforeAPI');
            var response = await this.callAPI(req,'students')
            //handle response
            if(response.Successful){
                 this.studentList=response.PersonList.slice()
                 var all = {id:-1, name:'--Select Student--'}
                 this.studentList.unshift(all)
                this.studentId=-1

                //setup the all
                this.studentList_wAll=response.PersonList.slice()
                var all2 = {id:-1, name:'--All Students--'}
                 this.studentList_wAll.unshift(all2)
                

            }else{
                this.responseMessage = response.Message
            }
            this.pleaseWait=false; 
          },
          async getSponsors(){
    
            
             
            this.$store.dispatch('beforeAPI');
            var response = await this.callAPI(null,'sponsors')
            //handle response
            if(response.Successful){
                this.sponsorList=response.ValueList
                var all = {ID:-1, Name:'--All Sponsors--'}
                this.sponsorList.unshift(all)
                this.filterBySponsor='--All Sponsors--'
            }else{
                this.responseMessage = response.Message
            }
            this.pleaseWait=false; 
        },
        async getTestTypes(){
            this.$store.dispatch('beforeAPI');
            var response = await this.callAPI(null,'testtypes')
            //handle response
            if(response.Successful){
                this.testTypeList=response.ValueList
                var all = {ID:-1, Name:'--All Test Types--'}
                this.testTypeList.unshift(all)
                this.filterByTestTypeId=-1
            }else{
                this.responseMessage = response.Message
            }
            this.pleaseWait=false; 
        },
          goreport(){
            this.responseMessage=null
            switch (this.selectedReport.key) {
                case 'grade':
                    if(this.studentId==-1){return}
                    this.gograde()
                    break;
                case 'time':
                    if(this.studentId==-1){return}
                    this.gotime()             
                    break;
                case 'timegrade':
                    if(this.studentId==-1){return}
                    this.gotimegrade();
                    break;
                case 'newstudent':
                    this.gonewstudent();
                    break;
                case 'who':
                    this.gowhotrainedwho();
                    break;
                case 'tpr':
                    this.gotpr();
                    break;
                case 'onboard':
                    this.goonboard();
                    break;
                case 'test':
                    this.gotest();
                    break;
                case 'sponsor':
                    this.gosponsor();
                    break;
                case 'checklist':
                    this.gochecklist();
                    break;
                case 'certificate':
                    this.gocertificate();
                    break;
                default:
                    break;
            }
          },
        async gograde(){
            let req = {
                StudentID:this.studentId,
                LocationID:this.locationId,

            }
             this.$store.dispatch('beforeAPI');
            var response = await this.callAPI(req,'data_grade_eval')
            //handle response
            if(response.Successful){
                 this.gradedata = response



            }else{
                this.responseMessage = response.Message
            }
            this.pleaseWait=false; 
        },
        async gotime(){
            let req = {
                StudentID:this.studentId,
                LocationID:this.locationId,

            }
             this.$store.dispatch('beforeAPI');
            var response = await this.callAPI(req,'data_time')
            //handle response
            if(response.Successful){
                 this.timedata = response.student
            }else{
                this.responseMessage = response.Message
            }
            this.pleaseWait=false; 
        },
        async gotimegrade(){
            let req = {
                StudentID:this.studentId,
                LocationID:this.locationId,

            }
             this.$store.dispatch('beforeAPI');
            var response = await this.callAPI(req,'data_timegrade')
            //handle response
            if(response.Successful){
                 this.timegradedata = response.student
                 this.instructorString = response.instructorString
                 this.getComments()
            }else{
                this.responseMessage = response.Message
            }
            this.pleaseWait=false; 
        },
        async gonewstudent(){
            //'figure out product name'
            var name=''
            var id = this.filterByProductId
            this.productList.forEach(myFunction2)
                function myFunction2(item, index, arr) {
                    let comp=arr[index]
                    if (comp.id==id){
                        name=comp.name
                    }
                }
                this.selectedProductName=name

                var lname=''
                var lid = this.locationId
                this.locationList.forEach(myFunction2)
                function myFunction2(item, index, arr) {
                    let comp=arr[index]
                    if (comp.id==lid){
                        lname=comp.name
                    }
                }
                this.selectedLocationName=lname


            let req = {
                LocationID:this.locationId,
                date1:this.date1,
                date2:this.date2,
                productId:this.filterByProductId,
                sponsor:this.filterBySponsor,
                studentGroupId:this.filterByStudentGroupId,
                startDate:this.startdate,
                statusList:this.statusList
            }
             this.$store.dispatch('beforeAPI');
            var response = await this.callAPI(req,'data_newstudent')
            //handle response
            if(response.Successful){
                 this.newstudentdata = response.studentList
            }else{
                this.responseMessage = response.Message
            }
            this.pleaseWait=false; 
        },
        async gosponsor(){
            this.responseMessage=null
            if(this.filterBySponsor=='--All Sponsors--'){
                this.responseMessage='Please select a sponsor';
              //  console.log('sp ' + this.filterBySponsor)
                return;
            }

            let req = {
                LocationID:this.locationId,
                date1:this.date1,
                date2:this.date2,
                sponsor:this.filterBySponsor,
           
            }
             this.$store.dispatch('beforeAPI');
            var response = await this.callAPI(req,'data_sponsor')
            //handle response
            if(response.Successful){
                 this.sponsordata = response.studentList
                 this.sponsordatelist = response.dateList
            }else{
                this.responseMessage = response.Message
            }
            this.pleaseWait=false; 
        },
        async gowhotrainedwho(){
            let req = {
                ProcedureFilter:{
                    StudentID:-1,
                    InstructorID:-1,
                    LocationID:this.locationId,
                    StartDateTime:this.date1,
                    EndDateTime:this.date2,
                    IncludeOnlyActiveStatus:false,
                    IncludeOnlyCompleteStatus:false,
                    IncludeOnlyIncompleteStatus:false,
                    ProcedureInstanceStatusID:-1,
                    includeAllUnsubmitted:false,
                    procedureSequenceId:-1

                }
                
            }
             this.$store.dispatch('beforeAPI');
            var response = await this.callAPI(req,'filtergrades')
            //handle response
            if(response.Successful){
                 this.whotrainedwhodata=response.GradedProcedures
                 this.pleaseWait=false;
                 
                
            }else{
                this.responseMessage = response.Message
            }
        },
        async gotpr(){
            //'figure out product name'
            var name=''
            var id = this.filterByProductId
            this.productList.forEach(myFunction2)
                function myFunction2(item, index, arr) {
                    let comp=arr[index]
                    if (comp.id==id){
                        name=comp.name
                    }
                }
                this.selectedProductName=name
            let req = {
                LocationID:this.locationId,
                date1:this.date1,
                date2:this.date2,
                eventID:this.filterByProductId,
                StudentID:this.studentId,
                startDate:this.startdate
            }
             this.$store.dispatch('beforeAPI');
            var response = await this.callAPI(req,'data_tpr')
            //handle response
            if(response.Successful){
                 this.tprdata = response.tprDataList
            }else{
                this.responseMessage = response.Message
            }
            this.pleaseWait=false; 
        },
        async goonboard(){
            //'figure out product name'
            var name=''
            
            let req = {
                LocationID:this.locationId,
                date1:this.date1,
                studentGroupId:this.filterByStudentGroupId
            }
             this.$store.dispatch('beforeAPI');
            var response = await this.callAPI(req,'data_onboardtracker')
            //handle response
            if(response.Successful){
                 this.onboardstudents = response.studentList
                 this.onboardprocdates=response.procDateList
            }else{
                this.responseMessage = response.Message
            }
            this.pleaseWait=false; 
        },
        async gotest(){
            //'figure out product name'
            var name=''
            
            let req = {
                LocationID:this.locationId,
                date1:this.date1,
                date2:this.date2,
                StudentID:this.studentId,
                testTypeId:this.filterByTestTypeId
            }
             this.$store.dispatch('beforeAPI');
            var response = await this.callAPI(req,'data_test')
            //handle response
            if(response.Successful){
                 this.teststudents = response.studentList
                 this.testdates=response.procDateList
                 if(this.teststudents.length==0){this.responseMessage="There are no tests entered for this student"}
            }else{
                this.responseMessage = response.Message
            }
            this.pleaseWait=false; 
        },
        async gochecklist(){
            
            

            let req = {
                id:this.studentId,
                
            }
             this.$store.dispatch('beforeAPI');
            var response = await this.callAPI(req,'checklistperson')
            //handle response
            if(response.Successful){
                 this.checklistdata = response.checkListItemPersonList
                 let mythis=this
                 this.checklistdata.forEach(myFunction2)
                    
                    function myFunction2(item, index, arr) {
                        let comp=arr[index]
                        comp.checkedDateTime = mythis.$options.filters.formatDateForInput(comp.checkedDateTime)
                        if(comp.checkedDateTime=='1-01-01'){comp.checkedDateTime=null}

                        //if this is one we just checked, then unlock it!
                        if(mythis.lastcheckeditem!=null){
                            if(comp.name==mythis.lastcheckeditem.name){comp.locked=false}
                        }
                        
                    } 
                    this.getttstudent()
            }else{
                this.responseMessage = response.Message
            }
            this.pleaseWait=false; 
        },
        async gocertificate(){
            let req = {
                id:this.studentId,
                
            }
             this.$store.dispatch('beforeAPI');
            var response = await this.callAPI(req,'certificate')
            //handle response
            if(response.Successful){
                 this.certificateData = response.Certificate
           
            }else{
                this.responseMessage = response.Message
            }
            this.pleaseWait=false; 
        },
        async getttstudent(){
            
            let req = {
                id:this.studentId,
                
            }
             this.$store.dispatch('beforeAPI');
            var response = await this.callAPI(req,'ttstudent')
            //handle response
            if(response.Successful){
                 this.checklistcontact = response.Person
           
            }else{
                this.responseMessage = response.Message
            }
            this.pleaseWait=false; 
        },
        getscore(job,date){
            var scope = this
            var indate = this.$options.filters.formatDate(date)
            var score

            var mylist = this.gradedata.timeAndDateList.datalist

            for(var i = 0; i< mylist.length;i++){
                let comp = mylist[i];
                let itemdate = scope.$options.filters.formatDate(comp.itemDate)
                if(job==comp.Name && itemdate ==indate){
                       score=comp.Grade
                       break;
                }
            }
            //console.log('score ' + score)
           /*  this.gradedata.timeAndDateList.datalist.forEach(myFunction2)
                function myFunction2(item, index, arr) {
                    let comp=arr[index]
                    let itemdate = scope.$options.filters.formatDate(comp.itemDate)
                    if(job==comp.Name && itemdate ==indate){
                       score=comp.Grade
                       
                    }
                } */
               // console.log('got score')

            //format
            if(score!=undefined){
                if(score.includes('.')){
                    score = Number(score).toFixed(2)
                }
            }
            
            return score
        },
        gettime(job,date){
            var scope = this
            var indate = this.$options.filters.formatDate(date)
            var score=0
            this.timedata.timeAndDateList.datalist.forEach(myFunction2)
                function myFunction2(item, index, arr) {
                    let comp=arr[index]
                    let itemdate = scope.$options.filters.formatDate(comp.itemDate)
                    if(job==comp.Name && itemdate ==indate){
                        var time = Number(comp.Grade).toFixed(2)
                        score=Number(score) + Number(time)
                      // console.log('got score ' + job + ' ' + date + ' ' + score)
                    }
                }
                

            //format
            if(score!=undefined){
               // if(score.includes('.')){
                    score = Number(score).toFixed(2)
                //}
            }
            if(score==0){score=null}
            return score
        },
        gettime_totalforjob(job){
            var scope = this
            var score=0
            this.timedata.timeAndDateList.datalist.forEach(myFunction2)
                function myFunction2(item, index, arr) {
                    let comp=arr[index]
                    if(job==comp.Name ){
                        if(comp.Grade!=undefined){
                            
                            var time = Number(comp.Grade).toFixed(2)
                            score=Number(score) + Number(time)
                           // console.log('time ' + job + ' ' + time)
                        }
                       
                    }
                }
                

            //format
            if(score!=undefined){
             
                    score = Number(score).toFixed(2)
                
            }
           // console.log('got score' + score)
            return score
        },
        gettimetotal(timelist){
            var time=0
            timelist.datalist.forEach(myFunction2)
                function myFunction2(item, index, arr) {
                    let comp=arr[index]
                    time = Number(time) + Number(comp.Grade)
                    
                }
             //format
             if(time!=undefined){ time = Number(time).toFixed(2) }    
            return time
        },
        getgradetotal_applytotaltype(timelist){
            if(timelist.datalist[0].useAvgForTheoryFinal){
                var lastindex = timelist.datalist.length-1
                var final = timelist.datalist[lastindex].Grade
               // console.log('theory final ' + final)
                return final
            }
            var total=0;
            var count=0;
            var avg;
            var outof100;
            var passcount =0;
            var pfcount = -1;
            var isfail = false;
            timelist.datalist.forEach(myFunction2)
                function myFunction2(item, index, arr) {
                    let comp=arr[index]
                    if(comp.Grade!=null ){
                        if(comp.Grade!='NA' && comp.Grade!='IA'){
                            total = total + Number(comp.Grade)
                            count = count + 1
                        }
                        
                    }
                    if(comp.Grade=='P'){
                        passcount = passcount + 1
                        
                    }
                    pfcount=pfcount + 1
                    if(comp.isfail){isfail=true}
                }
           // console.log(timelist.name + ' ' + count + ' ' + total)
            
            avg = total/count;
            //console.log('avg ' + avg)
            var weight = timelist.datalist[0].tag

            //console.log('weight ' + weight)
            /* if(weight > 0 ){
                avg = avg / weight * 100
            } */
                    
             

            outof100=100-total;

            
            if(isNaN(avg)){avg=0}
            if(isNaN(outof100)){outof100=0}
            if(isNaN(total)){total=0}
            
            
            if(timelist.name.includes('(Avg %)')){
               // console.log (timelist.name + ' avg ' + avg + ' weight:' + weight)
                avg = avg / weight * 100
                this.calcTotal = avg.toFixed(0);
                if(avg==0){return ''}
                return  avg.toFixed(0);
            }else if(timelist.name.includes('(%)')){

               // console.log (timelist.name + ' ct ' + count + ' tot:'  + total)
                avg = avg * 100 /weight
                this.calcTotal = avg.toFixed(0);
                if(avg==0){return ''}
                return  avg.toFixed(0);
            }else if(timelist.name.includes('(Avg)')){

               // console.log (timelist.name + ' avg ' + avg)
            
                this.calcTotal = avg.toFixed(0);
                if(avg==0){return ''}
                return  avg.toFixed(0);
            }else if(timelist.name.includes('(Out of 100)')){
                this.calcTotal=outof100
                if(outof100==100){return 'N/A'}
                return  outof100;
            }else if(timelist.name.includes('(Tract)')){
                if(isfail) {return this.formatTextForPassFail('Failed')}
                else{return this.formatTextForPassFail('Passed')}
            }else if(timelist.name.includes('(Total)')){
                this.calcTotal=total
                if(total==0){return ''}
                return total + ' (of ' + count + ')'
            }else{
                this.calcTotal=total
                if(total==0){return ''}
                return total
            }


             //if(time!=undefined){ time = Number(time).toFixed(2) }    
            //return time
        },

        //////////////////////////

        ////////////////////////////

        async getComments(){
            var scope = this
            this.comments=[] //reset

            this.timegradedata.jobList.forEach(myFunction2)
                function myFunction2(item, index, arr) {
                    let proc=arr[index]
                    scope.getcomments_byproc(proc)
                   
                }
        },
        async getcomments_byproc(proc){
            let req = {
                ProcedureInstance:{
                    ProcedureSequenceId:proc,
                    StudentID:this.studentId
                },
            }
            this.$store.dispatch('beforeAPI');
            var response = await this.callAPI(req,'comments')
            //handle response
            if(response.Successful){
                this.comments.push(response.comments)
            }else{
                this.responseMessage = response.Message
            }
            this.pleaseWait=false; 
        },

        gettimetotal_all(btw=false){
            var total=0

            this.timegradedata.timeLists.forEach(myFunction2)
    
                function myFunction2(item, index, arr) {
                    let timelist=arr[index]
                    if((btw && timelist.isEvent) || (!btw && !timelist.isEvent)){
                        var time =0
                        timelist.datalist.forEach(myFunction2)
                            function myFunction2(item, index, arr) {
                                let comp=arr[index]
                                time = Number(time) + Number(comp.Grade)
                                
                            }
                        total = total + time
                    }
                    
                }
                //format
             if(total!=undefined){ total = Number(total).toFixed(2) }  
            return total
        },
        async updatecheck(s,ischeck=false){
            this.responseMessage=""
            this.lastcheckeditem=null
            s.personId=this.studentId
            if(s.checked){
                if(s.personId_CheckedBy==null || s.personId_CheckedBy<=0){
                    s.personId_CheckedBy=this.loggedInUser.user_personId
                }

                //keep track of the one we checked!
               if(ischeck){
                this.lastcheckeditem=s
               }
                
            }else{
                s.personId_CheckedBy=null
                s.checkedDateTime='1/1/0001'
            }
            
            let req = {
                checkListItemPerson:s
            }
            this.$store.dispatch('beforeAPI');
            this.checklistresponseMessage=null
            var response = await this.callAPI(req,'updatechecklistitemperson')
            //handle response
            if(response.Successful){
                this.gochecklist()
            }else{
                this.responseMessage = response.Message
            }
            this.pleaseWait=false; 
        },
        setupOptionalReports(){
            if(window.SHOW_CHECKLIST_ON_REPORT!=undefined){
                if(window.SHOW_CHECKLIST_ON_REPORT){
                   let creport= {
                        id:10,
                        key:'checklist',
                        name:'Student Check List',
                        divName:'checklistdiv'
                    }
                    this.reportList.push(creport)
                }
            }
        },
        async getInstructors(){
       
            let req = {
                LocationID:this.locationId
            }
             this.$store.dispatch('beforeAPI');
            var response = await this.callAPI(req,'instructors')
            //handle response
            if(response.Successful){
                 this.instructorList=response.PersonList
           
            }else{
                this.responseMessage = response.Message
            }
            this.pleaseWait=false; 
          },
          async getStatusList(){
            let req = {
                LocationID:this.locationId
            }
             this.$store.dispatch('beforeAPI');
            var response = await this.callAPI(req,'employeestatus')
            //handle response
            if(response.Successful){
                 this.statusList=response.ValueList
                 this.statusList.forEach(myFunction2)
                function myFunction2(item, index, arr) {
                    let comp=arr[index]
                    comp.IsDefault=true
                }
                 
           
            }else{
                this.responseMessage = response.Message
            }
            this.pleaseWait=false; 
          },
          async getLocationsWithAll(){
       
            let req = {
                id:this.loggedInUser.user_personId
            }
             this.$store.dispatch('beforeAPI');
            var response = await this.callAPI(req,'locations')
            //handle response
            if(response.Successful){
                 this.locationListWithAll=response.GradeLocations
                 this.pleaseWait=false; 
                 var all = {id:-1,name: '--All Locations--'}
                 this.locationListWithAll.unshift(all)
               
                
            }else{
                this.responseMessage = response.Message
            }
            this.pleaseWait=false; 
          },
          async getProcedures(){
       
            let req = {
                id:-1
            }
             this.$store.dispatch('beforeAPI');
            var response = await this.callAPI(req,'certcourses')

            //handle response
            if(response.Successful){
                 this.procedureList=response.ValueList
                 var all = {id:-1, Name:'--Select Course--'}
                 this.procedureList.unshift(all)
                this.certProcedureName='--Select Course--'
            }else{
                this.responseMessage = response.Message
            }
            this.pleaseWait=false; 
          },
      },
      
      mounted(){
        this.date2=this.$options.filters.formatDateForInputToday()
        let today =new Date();
        this.date1 =new Date().setDate(today.getDate()-30);
        this.date1=this.$options.filters.formatDateForInput( this.date1)
        this.locationId = this.loggedInUser.LocationId
        this.getLocationsWithAll()
        this.getStudents()
        this.getInstructors()
       // this.getProducts()
        this.getSponsors()
        this.getTestTypes()
        this.setupOptionalReports()
        this.getStatusList()
        this.getProcedures()
      },
      
  }
  </script>
  
  <style>
 
.filesbutton{

border: none;
border-radius:5px;
min-width:130px;
height:30px;
padding:0px;
font-size: medium;
margin:5px;
text-decoration: none;

}
  .bold{
    font-weight: bold;
  }
  .homeDiv{
    display:grid;
    grid-template-rows: auto auto auto;
  }
  .buttonsreport{
    display:flex;
    width:98vw;
    flex-direction: row;
    
  }
  .filter{
    padding-left:10px;
  }
  .joblabelfixed{
    width:300px;
    text-align: left;
  }
  .joblabellink{
    width:300px;
    text-align: left;
    margin-top:10px;
    margin-bottom:10px;
  }
  .studentlabelfixed{
    width:200px;
    min-width:200px;
    text-align: left;
  }
  .studentlabelfixed_tpr{
    width:120px;
    min-width:120px;
    text-align: left;
  }
  .otlabelfixed{
    width:80px;
    min-width:80px;
    text-align: left;
  }
  .otlabelfixed_test{
    width:80px;
    min-width:80px;
    text-align: left;
    padding-left:5px;
  }
  .leftborder{
    border-left:1px solid gray;
  }
  .otfail{
    color:red;
  }
  .otpass{
    color:green;
  }
  .otlabelheaderfixed{
    width:80px;
    min-width:80px;
    text-align: left;
    font-size:smaller;
  }
  .otlabelheaderfixed_test{
    width:80px;
    min-width:80px;
    text-align: left;
    font-size:smaller;
    padding-left:5px;
  }
  .subheaderfixed{
    /* width:90px; */
    text-align: left;
    font-size:smaller;
    font-weight: bold;
  }
  .scorelabelfixed{
    width:120px;
  }
  .timelabelfixed{
    width:150px;
  }
  .datelabelfixed{
    width:180px;
  }
  .datelabelfixedmed{
    width:160px;
  }
  .datelabelfixednarrow{
    width:100px;
  }
  .timeleftlabelfixed{
    width:200px;
    text-align: left;
  }
  .bottombordered{
    border-bottom: 1px solid gray;
    margin:0px;
  }
.smallmargin{
    margin:0px;
}
.centered{
    text-align:center;
}
.reportlogo{
    height:40px;
}
.reportframe{
    background-color:var(--frame-color);
    padding:10px;
    
}
.blue{
    color:blue;
}
.timegradedatadiv{
    display:grid;
    grid-template-columns: auto 500px auto;
}
.detailbutton{
   

border: none;
border-radius:5px;
min-width:120px;
height:30px;
padding:0px;
font-size: medium;
margin:5px;
text-decoration: none;

}
.checkbox{
    margin-left:8px;
    margin-top:0px;
}
.checklabel{
    margin-top:1px;
}
.scrollvert{
  max-height:40vh;
  /* max-height:100%; */
  overflow-y: auto;
  overflow-x:hidden;
  margin:0px;


}
.checklistscroll{
    height:45vh;
  /* max-height:100%; */
  overflow-y: auto;
  overflow-x:hidden;
  margin:0px;
}
.commentclass{
    margin:5px 60px 15px 60px;
}
.commentclasstitle{
    margin:15px 0px 0px 0px;
}
.commentheader{
    font-size:larger;
}
.padded{
    padding-left:20px;
}
.rightjustified{
    text-align: right;


}
.margin10{
    margin:10px;
}
.leftborder2{
    border-left:1px solid gray;
    margin:0px 0px 0px 0px;
   padding-left:5px;
   /* padding-top:5px; */
   width:85px;
}
.leftbordersub{
    border-left:1px solid gray;
    margin:0px 0px 0px 0px;
   padding-left:5px;
   /* padding-top:5px; */
   width:85px;
}
.leftbordersub_test{
    border-left:1px solid gray;
    margin:0px 0px 0px 0px;
   padding-left:5px;
   /* padding-top:5px; */
   width:80px;
   margin:0px;
}
.nomargindiv{
    margin:0px;
    padding:0px;
    

}
.noborderdiv_test{
    margin:0px 0px 0px 0px;
   padding-left:5px;
   width:80px;
   margin:0px;
}
.reportdropdown{
    height:35px;
    margin:15px 10px 10px 10px; 
    min-width: 125px;
    font-size:inherit;
}
.checklistcheck{
    margin-top:20px;
}
.checklistinput{
    height:30px;
    font-size:inherit;
    margin-top:10px;
}
.darkfontdisabled:disabled {
    color:black;
    opacity: .9;
}
.certname{
    font-size:50px;
    
    /* text-shadow:3px 3px; */
    margin:30px 20px 15px 0px;
}
.certsub{
    font-size:x-large;
    
    margin:10px;
}
.certsign{  
    margin:5px 5px 40px 20px;
}
.certmargin{
     margin:120px 120px 120px 120px; 
    font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif
}
.reportlogocert{
    height:70px;
}
.signlong{
    width:200px;
 

}   
.signshort{
    
    width:100px;
  
}
.signdiv{

    text-align: right;
    width:200px;
    display:flex;
    flex-direction: column;
    align-items:flex-end;

}
@media print{
      .noprint .noprint *{
          display:none !important;
      }
    }

  </style>


